<script setup lang="ts"></script>

<template>
  <div class="h-screen">
    <slot />
    <UNotifications />
  </div>
</template>

<style scoped></style>
